(function() {

	let navIsFixed = false;
	let scrollOffset = document.querySelector('.section--banner').offsetHeight;
	let navFixedThreshold = scrollOffset;
	let navEl = document.querySelector('.header');

	function updateNavFixed() {
		if (window.scrollY > navFixedThreshold && !navIsFixed) {
			navEl.classList.add('header--fixed');
			navIsFixed = true;
		} else if (window.scrollY <= navFixedThreshold && navIsFixed) {
			navEl.classList.remove('header--fixed');
			navIsFixed = false;
		}
	}

	// function smoothScrollTo(e) {
	// 	let destEl = document.getElementById(e.currentTarget.getAttribute("href").substring(1));
	// 	let destY = destEl.offsetTop;
	// 	e.preventDefault();
	// 	window.scrollTo({
	// 		top: destY,
	// 		behavior: "smooth",
	// 	});
	// };

	// document.querySelectorAll('.headerNav__item').forEach((el) => {
	// 	el.addEventListener('click', smoothScrollTo);
	// });

	// register scroll listener
	window.addEventListener('scroll', (e) => updateNavFixed() );

})();
